//
// style special teaser
//

.SpecialTeaser {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 400px;
	padding: 2rem 1rem;
	background-color: #e91046;
	transition: .3s background-color cubic-bezier(.455, .03, .515, .955);
	&:hover {
		background-color: #bb1549;
	}
}

.tag {
	padding: .5rem .625rem;
    background-color: #000;
    font-size: .875rem;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.title {
	margin: 1rem 0 2rem;
	text-align: center;
	font-size: 2rem;
    line-height: 1.1;
    font-weight: 700;
    white-space: pre-line;
}

.number {
	margin: 1rem 0 0 0;
	font-size: 3rem;
    line-height: 1;
    font-weight: 300;
}